import React from 'react';
import { theme } from 'stitches.config';

export const HeartIcon = () => {
  return (
    <svg width="353" height="61" viewBox="0 0 353 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="136.013"
        y="21.7109"
        width="40.3909"
        height="48.5426"
        rx="18"
        transform="rotate(-45 136.013 21.7109)"
        fill={theme.colors.primaryCoralLight.toString()}
      />
      <path
        d="M200.116 14.2702C198.89 13.0438 197.435 12.071 195.833 11.4072C194.231 10.7435 192.514 10.4019 190.78 10.4019C189.046 10.4019 187.329 10.7435 185.727 11.4072C184.125 12.071 182.67 13.0438 181.444 14.2702L178.9 16.8142L176.356 14.2702C173.88 11.7942 170.522 10.4031 167.02 10.4031C163.518 10.4031 160.16 11.7942 157.684 14.2702C155.208 16.7463 153.817 20.1046 153.817 23.6062C153.817 27.1079 155.208 30.4662 157.684 32.9422L160.228 35.4862L178.9 54.1582L197.572 35.4862L200.116 32.9422C201.342 31.7164 202.315 30.261 202.979 28.6591C203.643 27.0572 203.984 25.3402 203.984 23.6062C203.984 21.8723 203.643 20.1553 202.979 18.5534C202.315 16.9515 201.342 15.4961 200.116 14.2702V14.2702Z"
        stroke={theme.colors.logoRed.toString()}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
