import React from 'react';

export const LibraryIcon = () => {
  return (
    <svg width="200" height="201" viewBox="0 0 200 201" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M162 30H42C36.4772 30 32 34.6371 32 40.3571V164.643C32 170.363 36.4772 175 42 175H162C167.523 175 172 170.363 172 164.643V40.3571C172 34.6371 167.523 30 162 30Z"
        fill="url(#paint0_linear_285_2497)"
      />
      <g filter="url(#filter0_d_285_2497)">
        <path
          d="M54 84.8066H189C190.326 84.8066 191.598 85.3334 192.536 86.2711C193.473 87.2088 194 88.4806 194 89.8066V114.807C194 116.133 193.473 117.404 192.536 118.342C191.598 119.28 190.326 119.807 189 119.807H54C52.6739 119.807 51.4021 119.28 50.4645 118.342C49.5268 117.404 49 116.133 49 114.807V89.8066C49 88.4806 49.5268 87.2088 50.4645 86.2711C51.4021 85.3334 52.6739 84.8066 54 84.8066V84.8066Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.3"
        d="M128 92.8066H102C100.343 92.8066 99 94.1498 99 95.8066C99 97.4635 100.343 98.8066 102 98.8066H128C129.657 98.8066 131 97.4635 131 95.8066C131 94.1498 129.657 92.8066 128 92.8066Z"
        fill="#80C6C8"
      />
      <path
        opacity="0.15"
        d="M146 105.807H102C100.343 105.807 99 107.15 99 108.807C99 110.463 100.343 111.807 102 111.807H146C147.657 111.807 149 110.463 149 108.807C149 107.15 147.657 105.807 146 105.807Z"
        fill="#80C6C8"
      />
      <path
        d="M79.5 111.807C84.7467 111.807 89 107.553 89 102.307C89 97.0599 84.7467 92.8066 79.5 92.8066C74.2533 92.8066 70 97.0599 70 102.307C70 107.553 74.2533 111.807 79.5 111.807Z"
        fill="#4A4177"
      />
      <g filter="url(#filter1_d_285_2497)">
        <path
          d="M11 127.807H146C147.326 127.807 148.598 128.333 149.536 129.271C150.473 130.209 151 131.481 151 132.807V157.807C151 159.133 150.473 160.404 149.536 161.342C148.598 162.28 147.326 162.807 146 162.807H11C9.67392 162.807 8.40215 162.28 7.46447 161.342C6.52678 160.404 6 159.133 6 157.807V132.807C6 131.481 6.52678 130.209 7.46447 129.271C8.40215 128.333 9.67392 127.807 11 127.807V127.807Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.3"
        d="M85 135.807H59C57.3431 135.807 56 137.15 56 138.807C56 140.463 57.3431 141.807 59 141.807H85C86.6569 141.807 88 140.463 88 138.807C88 137.15 86.6569 135.807 85 135.807Z"
        fill="#80C6C8"
      />
      <path
        opacity="0.15"
        d="M103 148.807H59C57.3431 148.807 56 150.15 56 151.807C56 153.463 57.3431 154.807 59 154.807H103C104.657 154.807 106 153.463 106 151.807C106 150.15 104.657 148.807 103 148.807Z"
        fill="#80C6C8"
      />
      <path
        d="M23.5 154.807C28.7467 154.807 33 150.553 33 145.307C33 140.06 28.7467 135.807 23.5 135.807C18.2533 135.807 14 140.06 14 145.307C14 150.553 18.2533 154.807 23.5 154.807Z"
        fill="#4A4177"
      />
      <g filter="url(#filter2_d_285_2497)">
        <path
          d="M146 41.8066H11C8.23858 41.8066 6 44.0452 6 46.8066V71.8066C6 74.5681 8.23858 76.8066 11 76.8066H146C148.761 76.8066 151 74.5681 151 71.8066V46.8066C151 44.0452 148.761 41.8066 146 41.8066Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.3"
        d="M83 49.8066H57C55.3431 49.8066 54 51.1498 54 52.8066C54 54.4635 55.3431 55.8066 57 55.8066H83C84.6569 55.8066 86 54.4635 86 52.8066C86 51.1498 84.6569 49.8066 83 49.8066Z"
        fill="#80C6C8"
      />
      <path
        opacity="0.15"
        d="M101 62.8066H57C55.3431 62.8066 54 64.1498 54 65.8066C54 67.4635 55.3431 68.8066 57 68.8066H101C102.657 68.8066 104 67.4635 104 65.8066C104 64.1498 102.657 62.8066 101 62.8066Z"
        fill="#80C6C8"
      />
      <path
        d="M36.5 68.8066C41.7467 68.8066 46 64.5533 46 59.3066C46 54.0599 41.7467 49.8066 36.5 49.8066C31.2533 49.8066 27 54.0599 27 59.3066C27 64.5533 31.2533 68.8066 36.5 68.8066Z"
        fill="#4A4177"
      />
      <defs>
        <filter
          id="filter0_d_285_2497"
          x="43"
          y="81.8066"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_285_2497" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_285_2497" result="shape" />
        </filter>
        <filter
          id="filter1_d_285_2497"
          x="0"
          y="124.807"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_285_2497" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_285_2497" result="shape" />
        </filter>
        <filter
          id="filter2_d_285_2497"
          x="0"
          y="38.8066"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_285_2497" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_285_2497" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_285_2497"
          x1="7"
          y1="66.4505"
          x2="140.014"
          y2="206.135"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#018E91" />
          <stop offset="1" stop-color="#4A4177" />
        </linearGradient>
      </defs>
    </svg>
  );
};
