//HR Dashboard IDs:
export const HR_DASHBOARD_INVITE_EMPLOYEE_BUTTON_ID = 'HR-Dashboard-Invite-Employee-Button' as const;
export const HR_DASHBOARD_INVITE_EMPLOYEE_SUBMIT_BUTTON_ID = 'HR-Dashboard-Invite-Employee-Submit-Button' as const;
export const HR_DASHBOARD_CANCEL_INVITE_EMPLOYEE_BUTTON_ID = 'HR-Dashboard-Cancel-Invite-Employee-Button' as const;
export const HR_MY_LEAVES_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID =
  'HR-My-Leaves-Dashboard-Request-New-Leave-Button' as const;
export const HR_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID = 'HR-Dashboard-Request-New-Leave-Button' as const;

//HR Employees Page IDs
export const HR_EMPLOYEES_INVITE_EMPLOYEE_BUTTON_ID = 'HR-Employees-Invite-Employee-Button' as const;
export const HR_EMPLOYEES_INVITE_EMPLOYEE_SUBMIT_BUTTON_ID = 'HR-Employees-Invite-Employee-Submit-Button' as const;
export const HR_EMPLOYEES_EDIT_EMPLOYEE_SUBMIT_BUTTON_ID = 'HR-Employees-Edit-Employee-Submit-Button' as const;
export const HR_EMPLOYEES_EDIT_EMPLOYEE_CANCEL_BUTTON_ID = 'HR-Employees-Edit-Employee-Cancel-Button' as const;
export const HR_EMPLOYEES_INVITE_EMPLOYEE_CANCEL_BUTTON_ID = 'HR-Employees-Invite-Employee-Cancel-Button' as const;

//HR Settings and Integrations Page IDs
export const HR_SETTINGS_INTEGRATIONS_DISCONNECT_WORKDAY_BUTTON_ID =
  'HR-Settings-Integrations-Disconnect-Workday-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_CANCEL_DISCONNECT_WORKDAY_BUTTON_ID =
  'HR-Settings-Integrations-Cancel-Disconnect-Workday-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_SFTP_CONFIGURATION_FORM_SUBMIT_BUTTON_ID =
  'HR-Settings-Integrations-SFTP-Configuration-Form-Submit-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_SFTP_CONFIGURATION_FORM_CANCEL_BUTTON_ID =
  'HR-Settings-Integrations-SFTP-Configuration-Form-Cancel-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_WORKDAY_CONFIGURATION_FORM_DISCONNECT_BUTTON_ID =
  'HR-Settings-Integrations-Workday-Configuration-Form-Disconnect-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_WORKDAY_CONFIGURATION_FORM_RESYNC_BUTTON_ID =
  'HR-Settings-Integrations-Workday-Configuration-Form-Resync-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_WORKDAY_CONFIGURATION_FORM_TEST_CONNECTION_BUTTON_ID =
  'HR-Settings-Integrations-Workday-Configuration-Form-Test-Connection-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_WORKDAY_CONFIGURATION_FORM_SAVE_BUTTON_ID =
  'HR-Settings-Integrations-Workday-Configuration-Form-Save-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_BAMBOOHR_CONFIGURATION_FORM_TEST_CONNECTION_BUTTON_ID =
  'HR-Settings-Integrations-BambooHR-Configuration-Form-Test-Connection-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_BAMBOOHR_CONFIGURATION_FORM_SAVE_BUTTON_ID =
  'HR-Settings-Integrations-BambooHR-Configuration-Form-Save-Button' as const;
export const HR_SETTINGS_INTEGRATIONS_BAMBOOHR_CONFIGURATION_FORM_CANCEL_BUTTON_ID =
  'HR-Settings-Integrations-BambooHR-Configuration-Form-Cancel-Button' as const;
export const HR_SETTINGS_BENEFITS_FMLA_EDITABLE_FORM_SAVE_BUTTON_ID =
  'HR-Settings-Benefits-Fmla-Editable-Form-Save-Button' as const;
export const HR_SETTINGS_BENEFITS_FMLA_EDITABLE_FORM_CANCEL_BUTTON_ID =
  'HR-Settings-Benefits-Fmla-Editable-Form-Cancel-Button' as const;
export const HR_SETTINGS_BENEFITS_STD_EDITABLE_FORM_SAVE_BUTTON_ID =
  'HR-Settings-Benefits-Std-Editable-Form-Save-Button' as const;
export const HR_SETTINGS_BENEFITS_STD_EDITABLE_FORM_CANCEL_BUTTON_ID =
  'HR-Settings-Benefits-Std-Editable-Form-Cancel-Button' as const;
export const HR_SETTINGS_BENEFITS_LTD_EDITABLE_FORM_SAVE_BUTTON_ID =
  'HR-Settings-Benefits-Ltd-Editable-Form-Save-Button' as const;
export const HR_SETTINGS_BENEFITS_LTD_EDITABLE_FORM_CANCEL_BUTTON_ID =
  'HR-Settings-Benefits-Ltd-Editable-Form-Cancel-Button' as const;

//HR PDP View IDs
export const HR_PDP_TIME_OFF_CALENDAR_TIME_OFF_MODAL_SUBMIT_BUTTON_ID =
  'HR-PDP-Time-Off-Calendar-Time-Off-Modal-Submit-Button' as const;
export const HR_PDP_TIME_OFF_CALENDAR_TIME_OFF_MODAL_CANCEL_BUTTON_ID =
  'HR-PDP-Time-Off-Calendar-Time-Off-Modal-Cancel-Button' as const;

// Birth Details
export const MGR_BIRTH_DETAILS_CLOSE_MODAL_BUTTON_ID = 'Mgr-Birth-Details-Close-Modal-Button' as const;

//Leaves Page
export const HR_LEAVES_PAGE_NEW_LEAVE_BUTTON_ID = 'HR-Leaves-Page-New-Leave-Button' as const;
