import { IPlanVM, IUserLeaveVM } from '@types';
import React from 'react';
import { styled } from 'stitches.config';

import styles from './DashboardPage.module.scss';
import LeavePlanListItem from './LeavePlanListItem';

interface ILeaveList {
  leaves: IUserLeaveVM[];
}
const UnstyledUl = styled('ul', {
  all: 'unset',
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  marginBlockEnd: '$16',
});
const LeaveList = ({ leaves }: ILeaveList) => {
  // NOTE: We still support plans created by CS and therefore do not have an associated leave record
  return (
    <div className={styles.container}>
      <UnstyledUl>
        {leaves
          .sort(
            (a: IUserLeaveVM, b: IUserLeaveVM) =>
              (new Date(b.expectedLeaveDate) as any) - (new Date(a.expectedLeaveDate) as any),
          )
          .map((leave) => {
            return <LeavePlanListItem key={leave.id} leave={leave as IUserLeaveVM} />;
          })}
      </UnstyledUl>
    </div>
  );
};

export default LeaveList;
