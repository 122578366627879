import { ExpandedClickableArea } from '@components/v2';
import { ELeaveStatus, ELeaveType, ELeaveTypeOptions, IPlanVM, IUserLeaveVM } from '@types';
import get from 'lodash/get';
import { formatDate } from '@utils/date-format';
import { Text } from '@components/v2';
import LeaveStatusBadge from '@components/LeaveStatusBadge/LeaveStatusBadge';
import Link from 'next/link';
import React from 'react';
import { Router } from 'router';

import { styled } from 'stitches.config';

interface LeavePlanListItemProps {
  leave?: IUserLeaveVM;
}

const Plan = styled('div', {
  backgroundColor: '$grey100',
  border: '1px solid $borderGrey',
  borderRadius: '$8',
  padding: '$24',
  display: 'flex',
  justifyContent: 'flex-start',
  '&:hover': {
    backgroundColor: '#EFF3F6', // note, not in our palette, https://ourtilt.slack.com/archives/C07UV24SYL8/p1731514217404779?thread_ts=1731461595.785849&cid=C07UV24SYL8
  },
});

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

function LeavePlanListItem({ leave }: LeavePlanListItemProps) {
  // NOTE: We still support plans created by CS and therefore do not have an associated leave record. This makes this component more complex.
  const getRowUrl = () => {
    if (leave.status === ELeaveStatus.APPROVED_HR) {
      return `${Router.routes.EMPLOYEE.VIEW_PLAN}?planId=${leave?.plan?.id}`;
    } else {
      return `${Router.routes.EMPLOYEE.EDIT_LEAVE}?leaveId=${leave.id}`;
    }
  };

  const employeeName = `${get(leave, 'employeeFirstName')} ${get(leave, 'employeeLastName')}`;

  const expectedLeaveDate = leave ? formatDate(leave.expectedLeaveDate) : null;
  const expectedReturnDate = leave ? formatDate(leave.expectedReturnDate) : null;
  return (
    <ExpandedClickableArea as="li" css={{ minWidth: '25rem' }}>
      <Link href={getRowUrl()} data-expand-click-area>
        <Plan>
          <Content>
            <Text css={{ margin: 0 }} TextType="allCaps12ptHeader" as="h3">
              {employeeName}
            </Text>
            <Text css={{ margin: 0 }} TextType="heading20" as="h2">
              {ELeaveTypeOptions[leave.leaveType]}
            </Text>
            {leave?.expectedLeaveDate && leave?.expectedReturnDate && (
              <Text TextType="nunito14" css={{ color: '$grey300' }}>
                {expectedLeaveDate} - {expectedReturnDate}
              </Text>
            )}
          </Content>
          <LeaveStatusBadge leave={leave} />
        </Plan>
      </Link>
    </ExpandedClickableArea>
  );
}

export default LeavePlanListItem;
