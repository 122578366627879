import React from 'react';
import { theme } from 'stitches.config';
export const QuestionIcon = () => {
  return (
    <svg width="63" height="61" viewBox="0 0 63 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="26.1" cy="26.6066" rx="25.8" ry="25.8" fill={theme.colors.primaryPeacockLight.toString()} />
      <path
        d="M33.9001 56.0063C47.155 56.0063 57.9001 45.2612 57.9001 32.0063C57.9001 18.7515 47.155 8.00635 33.9001 8.00635C20.6453 8.00635 9.90015 18.7515 9.90015 32.0063C9.90015 45.2612 20.6453 56.0063 33.9001 56.0063Z"
        stroke={theme.colors.primaryPeacock.toString()}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.916 24.8063C27.4803 23.2024 28.594 21.8498 30.0599 20.9883C31.5258 20.1267 33.2494 19.8118 34.9252 20.0993C36.6011 20.3867 38.1212 21.258 39.2162 22.5588C40.3112 23.8596 40.9105 25.506 40.908 27.2063C40.908 32.0063 33.708 34.4063 33.708 34.4063"
        stroke={theme.colors.primaryPeacock.toString()}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9001 41.6064V42.8064"
        stroke={theme.colors.primaryPeacock.toString()}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
