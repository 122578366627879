import {
  EE_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_WHAT_TO_KNOW_BEFORE_ENTERING_A_LEAVE_IN_TILT_ARTICLES_ID,
  KNOWLEDGE_CENTER_EXP_ARTICLES_FOR_EMPLOYEES_TAKING_A_LEAVE_OF_ABSENCE_ARTICLES_ID,
  KNOWLEDGE_CENTER_EXP_INTERMITTENT_LEAVES_OF_ABSENCE_ARTICLES_ID,
  KNOWLEDGE_CENTER_EXP_PAID_AND_UNPAID_STATE_BENEFIT_PROGRAMS_ARTICLES_ID,
  KNOWLEDGE_CENTER_EXP_RESOURCES_AND_INFORMATION_RELATED_TO_FMLA_ARTICLES_ID,
} from '@lib/pendo/pendo-ids';
import { IUserLeaveVM } from '@types';

export const HelpCenterUrl = 'https://help.hellotilt.com/en_us/categories/leave-support-faqs-S1fnVGCGo';

export interface Article {
  title: string;
  href: string;
  keywords: ArticleKeyword[];
  id: string;
}

export const enum WorkingStates {
  california = 'CA',
  colorado = 'CO',
  connecticut = 'CT',
  massachusetts = 'MA',
  newJersey = 'NJ',
  newYork = 'NY',
  oregon = 'OR',
  washington = 'WA',
}
export enum WorkingCities {
  sanFrancisco = 'San Francisco',
  districtOfColumbia = 'District of Columbia',
}
export enum ArticleKeyword {
  employee = 'employee',
  requestALeave = 'request-a-leave',
  fmla = 'fmla',
  nonSpecificStateArticles = 'non-specific',
  california = 'california',
  colorado = 'colorado',
  connecticut = 'connecticut',
  massachusetts = 'massachusetts',
  newJersey = 'new_jersey',
  newYork = 'new_york',
  oregon = 'oregon',
  washington = 'washington',
  sanFrancisco = 'san_francisco',
  districtOfColumbia = 'district_of_columbia',
  intermittentLeave = 'intermittent-leave',
}

export const requestALeaveArticles = [
  {
    title: 'What to know before entering a leave in Tilt',
    href: 'https://help.hellotilt.com/en_us/what-to-know-before-entering-a-leave-in-tilt-BJugmKZNn',
    keywords: [ArticleKeyword.requestALeave],
    id: EE_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_WHAT_TO_KNOW_BEFORE_ENTERING_A_LEAVE_IN_TILT_ARTICLES_ID,
  },
];
export const helpCenterArticles: Article[] = [
  {
    title: 'Articles for employees taking a leave of absence',
    href: 'https://help.hellotilt.com/en_us/categories/employees-rJLfb2zLi',
    keywords: [ArticleKeyword.employee],
    id: KNOWLEDGE_CENTER_EXP_ARTICLES_FOR_EMPLOYEES_TAKING_A_LEAVE_OF_ABSENCE_ARTICLES_ID,
  },
  {
    title: 'Resources and information related to FMLA',
    href: 'https://help.hellotilt.com/en_us/categories/fmla-ry0sKmqe2',
    keywords: [ArticleKeyword.fmla],
    id: KNOWLEDGE_CENTER_EXP_RESOURCES_AND_INFORMATION_RELATED_TO_FMLA_ARTICLES_ID,
  },
  {
    title: 'Paid and unpaid state benefit programs',
    href: 'https://help.hellotilt.com/en_us/categories/state-leave-laws-Sk50oGlvh',
    keywords: [ArticleKeyword.nonSpecificStateArticles],
    id: KNOWLEDGE_CENTER_EXP_PAID_AND_UNPAID_STATE_BENEFIT_PROGRAMS_ARTICLES_ID,
  },
  {
    title: 'Intermittent Leaves of Absence',
    href: 'https://help.hellotilt.com/en_us/intermittent-leaves-of-absence-SJk3Iz2aj',
    keywords: [ArticleKeyword.intermittentLeave],
    id: KNOWLEDGE_CENTER_EXP_INTERMITTENT_LEAVES_OF_ABSENCE_ARTICLES_ID,
  },
];
