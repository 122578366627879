export enum DOCUMENT_USER_ROLES {
  EMPLOYEE = 1,
  HR = 2,
  MANAGER = 3,
  LSM = 4,
}

export default {
  EMPLOYEE: 1,
  MANAGER: 2,
  BOTH: 3,
};
