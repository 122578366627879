import React from 'react';
import { HelpCenter } from './HelpCenter';
import { RequestALeave, IRequestALeaveButtonProps } from './RequestALeave';
import { KnowledgeCenterWrapper } from './components';
import { IUserLeaveVM } from '@types';

export enum ToggleOptions {
  MY_LEAVES = 'MY LEAVES',
  MY_TEAM_LEAVES = "MY TEAM'S LEAVES",
}
export enum ToggledHeaders {
  MY_LEAVES = 'My Leaves',
  MY_TEAM_LEAVES = "My Team's Leaves",
}
interface IProps {
  requestALeaveButtonProps: IRequestALeaveButtonProps;
  pendingLeave: IUserLeaveVM;
}

export const KnowledgeCenter = ({ requestALeaveButtonProps, pendingLeave }: IProps) => {
  return (
    <KnowledgeCenterWrapper>
      <RequestALeave requestALeaveButtonProps={requestALeaveButtonProps} pendingLeave={pendingLeave} />
      <HelpCenter />
    </KnowledgeCenterWrapper>
  );
};
