import React, { useEffect, useState } from 'react';
import useApi from '@api/transportLayer';
import OnLeaveIllustration from '@components/PlanNotification/OnLeaveIllustration';
import { LibraryIcon } from './KnowledgeCenter/assets/LibraryIcon';
import { ELeaveStatus, ECompanyStatus, IUserLeaveVM } from '@types';
import shouldBeLoggedIn from '@utils/should-be-logged-in';
import { useIdentity } from 'contexts/auth-context';
import { Text, Tooltip, Button } from '@components/v2';
import { Router } from 'router';
import FeatherIcon from '@components/FeatherIcon';
import { EPlanContext, usePlan } from 'contexts/plan-context';
import USER_ROLES from '@constants/user-roles';
import { FLAG_ENABLE_KNOWLEDGE_CENTER } from '@constants/flags';
import { KnowledgeCenter, ToggleOptions, ToggledHeaders } from './KnowledgeCenter';
import LeaveList from './LeaveList';
import { styled } from 'stitches.config';
import {
  EE_MY_LEAVES_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID,
  HR_MY_LEAVES_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID,
  MY_LEAVES_DASHBOARD_REQUEST_LEAVE_BUTTON_ID,
} from '@lib/pendo/pendo-ids';
import styles from './DashboardPage.module.scss';
import LayoutContent from '@components/LayoutContent';
import { IRequestALeaveButtonProps } from './KnowledgeCenter/RequestALeave';
import { TextSwitch } from '@ourtilt/tilt-ui-components';

const HeaderRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TooltipContent = styled('div', {
  '& h4': {
    margin: '0 0 8px 0',
  },
  '& p': {
    margin: 0,
  },
});

const Layout = styled('div', {
  width: '100%',
  margin: '0 auto',
  padding: '24px 100px',
  display: 'flex',
  justifyContent: 'center',
  gap: 40,

  variants: {
    layoutType: {
      twoColumn: {
        '& main': {
          flex: '1',
        },
        '& aside': {
          width: '400px',
        },
      },
    },
  },

  '@media (max-width: 767px)': {
    flexDirection: 'column',
    padding: '16px',
    alignItems: 'center',

    '& main': {
      flex: 'unset',
      width: '100%',
      maxWidth: '400px',
      minWidth: 'unset !important',
      margin: '0 auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      wordBreak: 'break-word',
      padding: '0 8px',
    },

    '& aside': {
      width: '100%',
      maxWidth: '400px',
      minWidth: 'unset',
      margin: '0 auto',
      textAlign: 'center',
      boxSizing: 'border-box',
      padding: '0 8px',
    },
  },
});

const StyledHr = styled('hr', {
  border: 'none',
  borderBottom: '1px solid $grey200',
});

const IllustrationContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

function DashboardPage() {
  const {
    state: { me },
  } = useIdentity();
  const { data: flags } = useApi.Flags.getFlags({ [FLAG_ENABLE_KNOWLEDGE_CENTER]: '' });
  const knowledgeCenterFlag = !!flags?.[FLAG_ENABLE_KNOWLEDGE_CENTER];
  const { dispatch: planDispatch } = usePlan();
  const { data: leaveData, isLoading: isLoadingLeaves } = useApi.EE.Leave.getMany();
  const leaves = leaveData?.results;

  const [myLeaves, managerLeaves] = React.useMemo(() => {
    if (!leaves || !me?.id) return [[], []];
    const myLeaves = [];
    const managerLeaves = [];
    leaves.forEach((leave) => {
      if (leave?.employeeId === me.id) myLeaves.push(leave);
      else managerLeaves.push(leave);
    });
    return [myLeaves, managerLeaves];
  }, [leaves, me?.id]);

  const isEE = me?.role === USER_ROLES.EMPLOYEE && managerLeaves.length === 0;
  const isHR = me?.role === USER_ROLES.MANAGER || managerLeaves.length > 0;
  let newLeaveButtonId = '';
  if (isEE) {
    newLeaveButtonId = EE_MY_LEAVES_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID;
  } else if (isHR) {
    newLeaveButtonId = HR_MY_LEAVES_DASHBOARD_REQUEST_NEW_LEAVE_BUTTON_ID;
  } else {
    newLeaveButtonId = MY_LEAVES_DASHBOARD_REQUEST_LEAVE_BUTTON_ID;
  }

  const pendingLeave = React.useMemo(() => {
    if (leaves) {
      return leaves
        .filter((leave) => leave.employeeId === me?.id)
        .find((leave) => leave.status === ELeaveStatus.SENT_TO_EMPLOYEE);
    }
  }, [leaves]);
  //  knowledge center toggle logic:
  const alignmentOptions = [ToggleOptions.MY_LEAVES, ToggleOptions.MY_TEAM_LEAVES];
  const [filteredLeaves, setFilteredLeaves] = useState(myLeaves);
  const [alignment, setAlignment] = useState(alignmentOptions[0]);

  const toggleAlignment = (newAlignment: string) => {
    const updatedAlignment = newAlignment as ToggleOptions;
    setAlignment(updatedAlignment);
    if (updatedAlignment === ToggleOptions.MY_LEAVES) {
      setFilteredLeaves(myLeaves);
    } else if (updatedAlignment === ToggleOptions.MY_TEAM_LEAVES) {
      setFilteredLeaves(managerLeaves);
    }
  };

  useEffect(() => {
    if (leaves) {
      planDispatch({ type: EPlanContext.SET_PLANS, payload: leaves.map((leave) => leave.plan) });
    }
    // knowledge center: set myLeaves to filteredLeaves on initial load, such as when clicking breadcrumb back to dashboard from PDP:
    if (knowledgeCenterFlag && alignment === alignmentOptions[0] && filteredLeaves.length === 0) {
      setFilteredLeaves(myLeaves);
    }
  }, [leaves]);

  if (isLoadingLeaves || !leaves || !myLeaves || !managerLeaves) {
    return null;
  }

  const requestLeaveButtonProps: IRequestALeaveButtonProps = {
    key: 'RequestLeaveButton',
    id: newLeaveButtonId,
    disabled:
      !!pendingLeave ||
      me?.company?.status === ECompanyStatus.DELETED ||
      me?.company?.status === ECompanyStatus.OFFBOARDING,
    type: 'button' as 'button',
    css: {
      gap: '$8',
      maxHeight: 45,
    },
    onClick: () => {
      Router.push(Router.routes.EMPLOYEE.NEW_LEAVE);
    },
  };
  const RequestLeaveButton = () => (
    <Button {...requestLeaveButtonProps}>
      <FeatherIcon name="PlusCircle" />
      New Leave
    </Button>
  );

  return (
    <>
      {knowledgeCenterFlag ? (
        <Layout id="dashboard-page" layoutType={'twoColumn'}>
          <main>
            <section>
              <HeaderRow>
                <Text css={{ marginBlockEnd: '$24' }} as="h2" TextType={'heading24'}>
                  {alignment === alignmentOptions[0] ? ToggledHeaders.MY_LEAVES : ToggledHeaders.MY_TEAM_LEAVES}
                </Text>
                {!me?.isContractor ? (
                  pendingLeave ? (
                    <Tooltip
                      key="pending-leave-tooltip"
                      style={{ maxWidth: 250 }}
                      content={
                        <TooltipContent>
                          <h4>Unsubmitted Leave</h4>
                          <p>
                            You cannot request a new leave because you have an unsubmitted leave. Please click on the
                            unsubmitted leave below and complete the form
                          </p>
                        </TooltipContent>
                      }
                      side="left"
                    >
                      <div>{!knowledgeCenterFlag && <RequestLeaveButton />}</div>
                    </Tooltip>
                  ) : (
                    !knowledgeCenterFlag && <RequestLeaveButton />
                  )
                ) : null}
                {knowledgeCenterFlag && managerLeaves.length > 0 && (
                  <TextSwitch options={alignmentOptions} alignment={alignment} setAlignment={toggleAlignment} />
                )}
              </HeaderRow>
              {filteredLeaves.length === 0 ? (
                <IllustrationContainer>
                  <LibraryIcon />
                  <Text
                    css={{ color: '$primaryEggplant', fontWeight: '$regular400', marginBlockEnd: 0 }}
                    as="h2"
                    TextType={'heading24'}
                  >
                    {alignment === alignmentOptions[0]
                      ? 'You don’t have any leaves'
                      : 'No one on your team is scheduled to take a leave'}
                  </Text>
                  <Text
                    css={{ color: '$grey300', textAlign: 'center', marginBlockStart: 0 }}
                    as="p"
                    TextType={'nunito18'}
                  >
                    {alignment === alignmentOptions[0]
                      ? 'It looks like you don’t have any leaves yet. Once you request a leave, come back here and we will help you get started.'
                      : 'It looks like no one on your team is scheduled to take a leave. Once they request a leave, come back here and we will help you get started.'}
                  </Text>
                </IllustrationContainer>
              ) : (
                <LeaveList leaves={filteredLeaves} />
              )}
            </section>
          </main>
          <aside>
            <KnowledgeCenter requestALeaveButtonProps={requestLeaveButtonProps} pendingLeave={pendingLeave} />
          </aside>
        </Layout>
      ) : (
        <LayoutContent>
          <HeaderRow>
            <Text css={{ marginBlockEnd: '$24' }} as="h2" TextType={'heading24'}>
              My Leaves
            </Text>
            {!me?.isContractor ? (
              pendingLeave ? (
                <Tooltip
                  key="pending-leave-tooltip"
                  style={{ maxWidth: 250 }}
                  content={
                    <TooltipContent>
                      <h4>Unsubmitted Leave</h4>
                      <p>
                        You cannot request a new leave because you have an unsubmitted leave. Please click on the
                        unsubmitted leave below and complete the form
                      </p>
                    </TooltipContent>
                  }
                  side="left"
                >
                  <div>
                    <RequestLeaveButton />
                  </div>
                </Tooltip>
              ) : (
                <RequestLeaveButton />
              )
            ) : null}
          </HeaderRow>
          {myLeaves.length === 0 ? (
            <div className={styles.illustrationContainer}>
              <OnLeaveIllustration className={styles.illustration} />
              <h2>You don’t have any leave plans.</h2>
              <p>
                Click <b>New Leave</b> to submit a new leave request.
              </p>
            </div>
          ) : (
            <LeaveList leaves={myLeaves} />
          )}
          {!knowledgeCenterFlag && managerLeaves.length > 0 && (
            <div>
              <hr className={styles.divider} />
              <Text css={{ marginBlockEnd: '$24' }} as="h2" TextType={'heading24'}>
                My Team&rsquo;s Leaves
              </Text>
              <LeaveList leaves={managerLeaves} />
            </div>
          )}
        </LayoutContent>
      )}
    </>
  );
}

export default shouldBeLoggedIn(DashboardPage);
