import { Button, ButtonColor, Tooltip } from '@components/v2';
import { Text } from '@components/v2/StyleGuide/ColorGuide/components/Text';
import { HeartIcon } from './assets/HeartIcon';
import { ArticleList, KnowledgeCenterContainer, KnowledgeCenterWrapper } from './components';
import { EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_BUTTON_ID } from '@lib/pendo/pendo-ids';
import { requestALeaveArticles } from './articles';
import { IUserLeaveVM } from '@types';
import { TooltipContent } from '@screens/DashboardPage';
export interface IRequestALeaveButtonProps {
  key: string;
  id: string;
  disabled: boolean;
  type: 'button';
  css: {
    gap: string;
    maxHeight: number;
  };
  onClick: () => void;
}
interface IProps {
  requestALeaveButtonProps: IRequestALeaveButtonProps;
  pendingLeave: IUserLeaveVM;
}

export const RequestALeave = ({ requestALeaveButtonProps, pendingLeave }: IProps) => {
  return (
    <KnowledgeCenterWrapper>
      <KnowledgeCenterContainer id="request-a-leave">
        <div className="padding-bottom">
          <HeartIcon />
        </div>
        <Text className="padding-bottom" TextType="heading24">
          Need to request a leave?
        </Text>
        <Text className="padding-bottom" TextType="nunito16">
          Let us help you determine what options you have and how to request the right type of leave.
        </Text>
        {pendingLeave ? (
          <Tooltip
            key="pending-leave-tooltip"
            style={{ maxWidth: 250 }}
            content={
              <TooltipContent>
                <h4>Unsubmitted Leave</h4>
                <p>
                  You cannot request a new leave because you have an unsubmitted leave. Please click on the leave and
                  complete the form.
                </p>
              </TooltipContent>
            }
            side="left"
          >
            <Button
              {...requestALeaveButtonProps}
              id={EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_BUTTON_ID}
              color={ButtonColor.EGGPLANT}
              fullWidth
            >
              Request a Leave
            </Button>
          </Tooltip>
        ) : (
          <Button
            {...requestALeaveButtonProps}
            id={EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_REQUEST_A_LEAVE_BUTTON_ID}
            color={ButtonColor.EGGPLANT}
            fullWidth
          >
            Request a Leave
          </Button>
        )}
        {requestALeaveArticles.length > 0 && (
          <>
            <Text TextType="nunito12" css={{ alignSelf: 'flex-start', color: '$grey400', fontWeight: '$semiBold700' }}>
              POPULAR RESOURCES
            </Text>
            <ArticleList>
              {requestALeaveArticles.map((article, idx) => {
                return (
                  <li key={idx}>
                    <a href={article.href} target="_blank" id={article.id}>
                      {article.title}
                    </a>
                  </li>
                );
              })}
            </ArticleList>
          </>
        )}
      </KnowledgeCenterContainer>
    </KnowledgeCenterWrapper>
  );
};
