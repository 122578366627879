import { ButtonColor } from '@components/v2/Button/Button';
import { Button } from '@components/v2';
import {
  EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_HELP_CENTER_BUTTON_ID,
  EE_PDP_KNOWLEDGE_CENTER_HELP_CENTER_BUTTON_ID,
} from '@lib/pendo/pendo-ids';
import { QuestionIcon } from './assets/QuestionIcon';
import { ArticleList, KnowledgeCenterContainer, PopularResourcesText } from './components';
import { PaddedText } from './components';
import { helpCenterArticles, HelpCenterUrl } from './articles';
interface IProps {
  isPDP?: boolean;
}

export const HelpCenter = ({ isPDP }: IProps) => (
  <KnowledgeCenterContainer id="help-center" className={isPDP ? 'pdp' : ''}>
    <div className={`padding-bottom ${isPDP ? 'padding-bottom-pdp' : ''}`}>
      <QuestionIcon />
      {isPDP && <PaddedText TextType="heading24">Have a question?</PaddedText>}
    </div>
    {!isPDP && <PaddedText TextType="heading24">Have a question, or don't know where to start?</PaddedText>}
    <div>
      <PaddedText TextType="nunito16">
        Discover how Tilt can simplify your leave process by exploring our Help Center.
      </PaddedText>
      <Button
        id={
          isPDP
            ? EE_PDP_KNOWLEDGE_CENTER_HELP_CENTER_BUTTON_ID
            : EE_MY_LEAVES_DASHBOARD_KNOWLEDGE_CENTER_HELP_CENTER_BUTTON_ID
        }
        color={ButtonColor.EGGPLANT}
        onClick={() => window.open(HelpCenterUrl, '_blank')}
        fullWidth
      >
        Help Center
      </Button>
    </div>
    {!isPDP && helpCenterArticles.length > 0 && (
      <>
        <PopularResourcesText>POPULAR RESOURCES</PopularResourcesText>
        <ArticleList>
          {helpCenterArticles.map((article, idx) => {
            return (
              <li key={idx}>
                <a href={article.href} target="_blank" id={article.id}>
                  {article.title}
                </a>
              </li>
            );
          })}
        </ArticleList>
      </>
    )}
  </KnowledgeCenterContainer>
);
