import React from 'react';
import { useIdentity } from 'contexts/auth-context';
import LogoImage from '@public/logo.svg';
import Image from 'next/legacy/image';
interface IProps {
  width?: number | string;
  height?: number | string;
  className?: string;
}

const Logo = ({ width = '100%', height = 64, className }: IProps) => {
  const {
    state: { me },
  } = useIdentity();

  const company = me?.company;

  const { logo, name } = company ?? {};

  const renderLogo = logo ? (
    <img
      width={width}
      height={height}
      className={className}
      alt={`${name} logo`}
      src={logo}
      data-testid="company-logo-image"
    />
  ) : (
    <Image
      src={LogoImage}
      alt={`${name} logo`}
      objectFit="contain"
      // @ts-ignore
      height={height}
      // @ts-ignore
      width={width}
      className={className}
    />
  );

  return renderLogo;
};

export default Logo;
