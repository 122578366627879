import { styled } from 'stitches.config';
import { Text } from '@components/v2/StyleGuide/ColorGuide/components/Text';

export const KnowledgeCenterWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$24',
});

export const KnowledgeCenterContainer = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '25rem',
  gap: '$8',
  borderRadius: '$8',
  backgroundColor: '$white',
  padding: '$24',
  border: '1px solid $grey200',
  '&.pdp': {
    marginTop: '$24',
    padding: '$24 $24 0 $24',
  },
  '.padding-bottom-pdp': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '$16',
  },
  '.reverse-order': {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  button: {
    marginBlockStart: '$16',
    marginBlockEnd: '$32',
  },
});

export const PaddedText = styled(Text, {
  marginBlockEnd: '$8',
  textAlign: 'center',
});

export const PopularResourcesText = styled(Text, {
  alignSelf: 'flex-start',
  color: '$grey400',
  fontWeight: '$semiBold700',
  fontFamily: '$nunito',
  fontSize: '$body12',
});

export const ArticleList = styled('ul', {
  display: 'flex',
  alignSelf: 'flex-start',
  flexDirection: 'column',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  li: {
    borderBottom: '1px solid $grey200',
    padding: '$16 0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
});
